import React from 'react'
import { Navbar,Nav,NavDropdown,Form,FormControl,Button,Dropdown } from 'react-bootstrap'

const menu = {
    "en":{
        "home":"HOME", "about":"ABOUT", "category":"CATEGORY", "apply":"APPLY", "awards":"AWARDS","ourproduct":"Our Products", "magazine":"Magazine & Gallery","dropdown":"Drop Down","rtprice":"Real-time Prices","fnews":"Financial News","histprice":"Historical Data","riskman":"Risk Management","traderecord":"Trade Record","memberrank":"Memberships", "terms":"RULES & TERMS","jury":"JURY","contact":"CONTACT","links":"LINKS"
    },
    "zh":{
        "home":"主頁", "quote":"實時報價", "aboutus":"關於我們", "awards":"Awards", "ourservice":"我們的業務","ourproduct":"我們的產品", "dropdown":"更多","rtprice":"實時市場價格","fnews":"財經新聞","histprice":"歷史價格圖表","riskman":"風險管理及入市計劃","traderecord":"交易詳情","memberrank":"會員階級制度"
    },
    "cn":{
        "home":"主页", "quote":"实时报价", "aboutus":"关于我们", "awards":"Awards","ourservice":"我们的业务","ourproduct":"我们的产品", "dropdown":"更多","rtprice":"实时市场价格","fnews":"财经新闻","histprice":"历史价格图表","riskman":"风险管理及入市计划","traderecord":"交易详情","memberrank":"会员阶级制度"
    },
}

class IYFAONavBarTop extends React.Component{
    constructor(props){
        super(props);
        this.state = {  
          
        };
    
        this.clicknav = this.clicknav.bind(this)

      }
componentDidMount(){
    document.title="International Young Painter Open Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/iypo-logo.PNG"
    link2.href="/assets/iypo-logo.PNG"
    this.props.setval({comp:"iypo",lang:"en"})
}
      clicknav(page,pos){
        if(page===this.props.pageSelected){
            document.getElementById(pos).scrollIntoView();
        }else {
            this.props.setval({pageSelected:page,pagepos:pos});           
        } 
      }
//{this.props.companyname[this.props.lang]}
    render(){
        return(
            <div>
                <div className="row">
                    <div className={["col-md-12","fullwidth"].join(" ")}>
                       
                            <Navbar style={{backgroundColor:"rgb(255,255,255)"}}  expand="lg" collapseOnSelect >
                                <Navbar.Brand className="navbar-brand" ><img src="../assets/iypo-logo.PNG" width="100px" height="100px"/></Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse className="navbar-collapse" id="basic-navbar-nav">
                                    <Nav className="mr-auto">
                                    {/* <Nav.Link className={"navbar-black"} style={{fontFamily:"Courgette",fontSize:"1.5rem",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"home"})
                                        }}>{menu[this.props.lang]["home"]}</Nav.Link> */}
                                         <NavDropdown id={"iypo-home"} style={{fontFamily:"Courgette",fontSize:"1.5rem",fontWeight:"bold",marginLeft:"10px"}} title={menu[this.props.lang]["home"]}>
                                         <NavDropdown.Item href="/iypo" >{menu[this.props.lang]["home"]}</NavDropdown.Item>
                                            <NavDropdown.Item href="/iypo/magazine" >{menu[this.props.lang]["magazine"]}</NavDropdown.Item>
                                        </NavDropdown>
                                    <Nav.Link className={"navbar-black"} style={{fontFamily:"Courgette",fontSize:"1.5rem",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo/about" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"about"})
                                        }}>{menu[this.props.lang]["about"]}</Nav.Link>
                                    <Nav.Link className={"navbar-black"} style={{fontFamily:"Courgette",fontSize:"1.5rem",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo/apply"onClick={(e)=>{
                                        //this.props.setval({pageSelected:"apply"})
                                        }}>{menu[this.props.lang]["apply"]}</Nav.Link>
                                    <Nav.Link className={"navbar-black"} style={{fontFamily:"Courgette",fontSize:"1.5rem",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo/category" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"category"})
                                        }}>{menu[this.props.lang]["category"]}</Nav.Link>
                                    <Nav.Link className={"navbar-black"} style={{fontFamily:"Courgette",fontSize:"1.5rem",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo/awards" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"awards"})
                                        }}>{menu[this.props.lang]["awards"]}</Nav.Link>
                                    <Nav.Link className={"navbar-black"} style={{fontFamily:"Courgette",fontSize:"1.5rem",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo/terms" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"terms"})
                                        }}>{menu[this.props.lang]["terms"]}</Nav.Link>
                                    {/* <Nav.Link style={{fontFamily:"Arial",fontSize:"1.5rem",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo/jury" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu[this.props.lang]["jury"]}</Nav.Link> */}
                                    <Nav.Link className={"navbar-black"} style={{fontFamily:"Courgette",fontSize:"1.5rem",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo/contact" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu[this.props.lang]["contact"]}</Nav.Link>
                                    <Nav.Link className={"navbar-black"} style={{fontFamily:"Courgette",fontSize:"1.5rem",color:"rgba(125, 104, 0,1)",fontWeight:"bold",marginLeft:"10px"}} href="/iypo/links" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu[this.props.lang]["links"]}</Nav.Link>
                                   
                                   <img src={"/assets/yt_logo.PNG"} alt={"YT_LOGO"} height={"65px"} className={"nav-ytlogo"} />

                                    </Nav>
                                   
                                </Navbar.Collapse>
                            </Navbar>
                         
                    </div>
                </div>
            </div>
        )  
    }
}

export default IYFAONavBarTop;