import React from 'react'
// import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Navbar,Nav,NavDropdown,Form,FormControl,Button,Dropdown,Container } from 'react-bootstrap'

const menu = {
    "en":{
        "home":"HOME", "about":"ABOUT", "category":"CATEGORY", "apply":"APPLY", "awards":"AWARDS","ourproduct":"Our Products","magazine":"MAGAZINE & GALLERY" ,"dropdown":"Drop Down","rtprice":"Real-time Prices","fnews":"Financial News","histprice":"Historical Data","riskman":"Risk Management","traderecord":"Trade Record","memberrank":"Memberships", "terms":"RULES","jury":"JURY","contact":"CONTACT","winners":"WINNERS","teacherlist":"OUTSTANDING MUSIC TEACHERS"
    },
    "zh":{
        "home":"主頁", "quote":"實時報價", "aboutus":"關於我們", "awards":"Awards", "ourservice":"我們的業務","ourproduct":"我們的產品", "dropdown":"更多","rtprice":"實時市場價格","fnews":"財經新聞","histprice":"歷史價格圖表","riskman":"風險管理及入市計劃","traderecord":"交易詳情","memberrank":"會員階級制度"
    },
    "cn":{
        "home":"主页", "quote":"实时报价", "aboutus":"关于我们", "awards":"Awards","ourservice":"我们的业务","ourproduct":"我们的产品", "dropdown":"更多","rtprice":"实时市场价格","fnews":"财经新闻","histprice":"历史价格图表","riskman":"风险管理及入市计划","traderecord":"交易详情","memberrank":"会员阶级制度"
    },
}

class NavBarTop extends React.Component{
    constructor(props){
        super(props);
        this.state = {  
          
        };
    
        this.clicknav = this.clicknav.bind(this)

      }
    componentDidMount(){
        document.title="International Classical Music Talent Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/icmt_logo.PNG"
    link2.href="/assets/icmt_logo.PNG"
        this.props.setval({comp:"icmt"})
    }
      clicknav(page,pos){
        if(page===this.props.pageSelected){
            document.getElementById(pos).scrollIntoView();
        }else {
            this.props.setval({pageSelected:page,pagepos:pos});           
        } 
      }
//{this.props.companyname[this.props.lang]}
    render(){
        return(
            <div>
                <div className="row">
                    <div className={["col-md-12"].join(" ")}>
                       
                            <Navbar sticky="top" expand="lg" className={["rednav"].join(" ")}>
                                <Navbar.Brand className="navbar-brand" href="/icmt"><img src="/assets/icmt_logo.PNG" width="100px" height="70px"/></Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto">
                                    <Nav.Link style={{fontFamily:"Optima",fontSize:"1.4rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"home"})
                                        }}>{menu[this.props.lang]["home"]}</Nav.Link>
                                         {/* <NavDropdown style={{fontFamily:"Optima",color:"white",fontWeight:"bold",marginLeft:"10px"}} title={menu[this.props.lang]["home"]}>
                                         <NavDropdown.Item href="/icmt" >{menu[this.props.lang]["home"]}</NavDropdown.Item>
                                            <NavDropdown.Item href="/icmt/magazine" >{menu[this.props.lang]["magazine"]}</NavDropdown.Item>
                                        </NavDropdown> */}

                                    <Nav.Link style={{fontFamily:"Optima",fontSize:"1.4rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt/about" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"about"})
                                        }}>{menu[this.props.lang]["about"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Optima",fontSize:"1.4rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt/apply"onClick={(e)=>{
                                        //this.props.setval({pageSelected:"apply"})
                                        }}>{menu[this.props.lang]["apply"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Optima",fontSize:"1.4rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt/category" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"category"})
                                        }}>{menu[this.props.lang]["category"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Optima",fontSize:"1.4rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt/awards" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"awards"})
                                        }}>{menu[this.props.lang]["awards"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Optima",fontSize:"1.4rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt/terms" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"terms"})
                                        }}>{menu[this.props.lang]["terms"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Optima",fontSize:"1.4rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt/jury" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu[this.props.lang]["jury"]}</Nav.Link>
                                    <Nav.Link style={{fontFamily:"Optima",fontSize:"1.4rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt/contact" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu[this.props.lang]["contact"]}</Nav.Link>
                                    {/* <Nav.Link style={{fontFamily:"Optima",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt/winners" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu[this.props.lang]["winners"]}</Nav.Link> */}
                                    <NavDropdown style={{fontFamily:"Optima",fontSize:"1.4rem",color:"white",fontWeight:"bold",marginLeft:"10px"}} title={menu[this.props.lang]["winners"]}>
                                        <NavDropdown.Item href="/icmt/winners" >{menu[this.props.lang]["winners"]}</NavDropdown.Item>
                                        <NavDropdown.Item href="/icmt/teacherlist" >{menu[this.props.lang]["teacherlist"]}</NavDropdown.Item>
                                    </NavDropdown>

                                    <img src={"/assets/yt_logo.PNG"} alt={"YT_LOGO"} height={"65px"} className={"nav-ytlogo"} />
                                    {/* <Nav.Link style={{fontFamily:"Optima",fontSize:"1.6em",color:"white",fontWeight:"bold",marginLeft:"10px"}} href="/icmt/winners" onClick={(e)=>{
                                        //this.props.setval({pageSelected:"jury"})
                                        }}>{menu[this.props.lang]["winners"]}</Nav.Link> */}
                                   
                                    </Nav>
                                   
                                </Navbar.Collapse>
                            </Navbar>
                         
                    </div>
                </div>
            </div>
        )  
    }
}

export default NavBarTop;