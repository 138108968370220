import '../../App.css';
import React, {Component, Container} from 'react';
import {Row,Col,Image,Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Card from './card';
import NavBarTop from "./navbar";
import Footer from "./footer";

var count = []

class Winners extends Component {
  constructor(props){
    super(props);
    this.state = {
       
    };

    this.setval = this.setval.bind(this)
  }

  setval(obj){
    this.setState(obj)
  }
  componentDidMount(){
    document.title="International Classical Music Talent Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/icmt_logo.PNG"
    link2.href="/assets/icmt_logo.PNG"
    this.props.setval({comp:"icmt"}) 
  }      
      
  render(){
    for(let i=1;i<=51;i++){
      count[i] = 51 + 1 - i

    }
    return (
      <div>

      <div className="home-content-div" style={{textAlign:"justify",textJustify:"inter-word", padding:"4% 6%",fontFamily:"Courgette"}}>
        
        <h2 className='winners-title'>International Classical Music Talent Competition</h2>
        <h5 className='winners-title'>Past Winners</h5>

        <div className={["winner-body"].join(" ")} >
         {
           count.map((item)=>{
            return  <img src={"../assets/icmt2023winners/IMG_"+parseInt(item)+".PNG"} className={["winner-card"].join(" ")} />
              
           })
         }  
        </div>
           
        <div style={{fontSize:"0.9rem",marginTop:"65px"}}>*The profiles on this page are contributed by contestants and the list is not exhausive.</div>
      </div>
      </div>
    );
  }
  }
  

export default Winners;
